import "the-new-css-reset/css/reset.css";
import FontFaceObserver from "fontfaceobserver";
import p5 from "p5";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollSmoother from "gsap/dist/ScrollSmoother";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import Lenis from "lenis";
import DrawSVGPlugin from "gsap/DrawSVGPlugin";
// swiper
import "swiper/css";
import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./style/common.css";
import { TextFill, TextFilp, TextMasking, TextTranslate, ImageContast, ButtonExtend } from "./Module";
import CardExtend from "./CardExtend";
import CardBGAnim from "./CardBGAnim";

import DTW from "./DTW";
import GTAA_Events from "./GTAA_Events";
import GTAA_About from "./GTAA_About";
import GTAA_TelcoLLM from "./GTAA_TelcoLLM";
function isTouchDevice() {
    return "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
}

const isMobile = isTouchDevice();

if (isMobile) {
    document.body.classList.add("view-mobile");
    initWindowHeight();

    ScrollTrigger.defaults({
        scroller: ".wrap", // 스크롤을 감지할 컨테이너
        immediateRender: true,
        autoRefreshEvents: "visibilitychange,DOMContentLoaded,load",
    });

    // ScrollTrigger.normalizeScroll(true);
}

// class EventDispatcher {
//     constructor() {
//         this.listeners = {};
//     }

//     addListener(event, callback) {
//         if (!this.listeners[event]) {
//             this.listeners[event] = [];
//         }
//         this.listeners[event].push(callback);
//     }

//     removeListener(event, callback) {
//         if (this.listeners[event]) {
//             this.listeners[event] = this.listeners[event].filter((listener) => listener !== callback);
//         }
//     }

//     dispatch(event, data) {
//         if (this.listeners[event]) {
//             this.listeners[event].forEach((callback) => callback(data));
//         }
//     }
// }

function initWindowHeight() {
    gsap.set(document.body, { "--vh": `${window.innerHeight}px` });
}

function resetScroll() {
    window.history.scrollRestoration = "manual";
    if (history.scrollRestoration) {
        history.scrollRestoration = "manual";
    }
    ScrollTrigger.clearScrollMemory("manual");
    ScrollTrigger.clearScrollMemory();
    window.onbeforeunload = function () {
        gsap.set(window, { scrollTo: { y: 0 } });
    };
    gsap.set(window, { scrollTo: { y: 0 } });
    gsap.set(".wrap", { scrollTo: { y: 0 } });

    if (document.body.classList.contains("view-mobile")) {
    }
}
function loadDTW24() {
    gsap.registerPlugin(DrawSVGPlugin);

    commonUtilEvents();
    new DTW();

    anchorMenu();
}
function loadPage() {
    resetScroll();

    // DTW24 Ignite

    if (document.querySelector(".wrap-dtw")) {
        loadDTW24();
    }

    if (document.querySelector(".gtaa-about")) {
        // loadGTAA_About()
        commonUtilEvents();
        new GTAA_About();
    }

    if (document.querySelector(".gtaa-events")) {
        // loadGTAA_Events()
        commonUtilEvents();
        new GTAA_Events();
    }

    if (document.querySelector(".gtaa-telco-llm")) {
        commonUtilEvents();
        new GTAA_TelcoLLM();
    }

    if (!document.body.classList.contains("view-mobile")) {
        // const smoother = ScrollSmoother.create({
        //     smooth: 0.7,
        //     speed: 2,
        //     effects: true,
        //     // content: document.querySelector(".wrap"),
        //     normalizeScroll: true,
        // });

        // window._smoother = smoother;

        // smoother.refresh();

        gsap.registerPlugin(ScrollTrigger);

        const lenis = new Lenis({
            smoothWheel: true,
            smoothTouch: true,
            lerp: 0.1,
            wheelMultiplier: 1.25,
            // Add this line to use the default easing for smoother scrolling
            easing: (p) => 1 - Math.pow(1 - p, 3), // Customize easing function if necessary
            // easing: (p) => 1 - Math.pow(2, -10 * p), // expo.out
            // easing: (p) => Math.pow(2, 10 * (p - 1)) - 0.001, // expo.easeIn
            // easing: (p) => ((p *= 2) < 1 ? 0.5 * Math.pow(2, 10 * (p - 1)) : 0.5 * (2 - Math.pow(2, -10 * (p - 1)))), // expo.easeInOut
        });

        lenis.on("scroll", ScrollTrigger.update);

        gsap.ticker.add((time) => {
            lenis.raf(time * 1000);
        });

        gsap.ticker.lagSmoothing(0);
    }

    headerEvent();

    if (ScrollTrigger.getById("bgTrigger")) {
        ScrollTrigger.getById("bgTrigger").refresh();
    }
}

function appearMenuLayer() {
    const button = document.querySelector(".button-menu-layer");

    button.setAttribute("data-open", true);

    const lines = gsap.utils.toArray(".line", button);
    const lineContainer = button.querySelector(".line-container");

    const links = gsap.utils.toArray(".mb-menu-layer .link-inner");

    gsap.to(lines[0], { y: 5.5, rotate: 45, duration: 0.3, ease: "power2.out" });
    gsap.to(lines[1], { y: -5.5, rotate: -45, duration: 0.3, ease: "power2.out" });

    const layer = document.querySelector(".mb-menu-layer");
    layer.classList.add("is-visible");
    const inner = layer.querySelector(".menu-inner");
    const bg = layer.querySelector(".menu-background");
    document.body.classList.add("is-lock");
    document.body.classList.add("is-open-menu");
    if (window._smoother) {
        window._smoother.paused(true);
    }
    gsap.set(layer, { display: "block" });
    gsap.to([bg], { opacity: 1, duration: 0.3, ease: "power1.inOut" });
    gsap.set(inner, {
        opacity: 1,
        duration: 0,
        delay: 0.3,
        onStart: () => {},
        onComplete: () => {
            gsap.delayedCall(0.25, () => {
                buttonExtendCustom(layer.querySelector(".link-button"));
            });
            gsap.set(layer, { pointerEvents: "auto" });
            gsap.utils.toArray(links).forEach((c, i) => {
                const _delay = i * 0.1;
                gsap.to(c, {
                    y: "0%",
                    duration: 0.55,
                    ease: "power3.out",
                    delay: _delay,
                });
                gsap.to(c, {
                    opacity: 1,
                    duration: 0.8,
                    ease: "power2.out",
                    delay: _delay,
                });
            });
        },
    });
}

function checkResize() {
    // console.log(window.innerWidth);
    if (window.innerWidth >= 1140) {
        // console.log("disappearMenuLayer");
        if (document.querySelector(".mb-menu-layer").classList.contains("is-visible")) {
            disappearMenuLayer();
        }

        if (document.querySelector(".header .gnb-container .link.selected")) {
            anchorAnim(document.querySelector(".header .gnb-container .link.selected").getAttribute("data-index"), true, true);
        }
    }

    if (!isMobile) {
    }
    initWindowHeight();
    ScrollTrigger.refresh();
}

window.onresize = () => checkResize();
function disappearMenuLayer(callback) {
    const layer = document.querySelector(".mb-menu-layer");
    layer.classList.remove("is-visible");
    const inner = layer.querySelector(".menu-inner");
    const bg = layer.querySelector(".menu-background");
    gsap.killTweensOf(layer, "all");
    gsap.set(layer, { pointerEvents: "none" });
    const links = gsap.utils.toArray(".mb-menu-layer .link-inner");
    gsap.to(bg, {
        opacity: 0,
        duration: 0.2,
        ease: "power2.out",
        delay: 0.1,
        onComplete: () => {
            document.body.classList.remove("is-lock");
            document.body.classList.remove("is-open-menu");
            if (callback) {
                callback();
            }
            if (window._smoother) {
                window._smoother.paused(false);
            }
            gsap.set(layer, { display: "none" });
            gsap.set([bg, inner], { opacity: 0 });

            const button = layer.querySelector(".link-button");
            gsap.killTweensOf(button, "all");
            gsap.killTweensOf(button.querySelector(".icon-wrap span"), "all");
            gsap.killTweensOf(button.querySelector(".txt"), "all");
            gsap.killTweensOf(button.querySelector(".background"), "all");
            gsap.set(button, { clearProps: "all" });
            gsap.set(button.querySelector(".icon-wrap span"), { clearProps: "all" });
            gsap.set(button.querySelector(".txt"), { clearProps: "all" });
            gsap.set(button.querySelector(".background"), { clearProps: "all" });

            links.forEach((link) => {
                gsap.killTweensOf(link, "all");
                gsap.set(link, { clearProps: "all" });
            });
        },
    });
    gsap.to([inner], {
        opacity: 0,
        duration: 0.2,
        ease: "power2.out",
        onStart: () => {},
    });

    const button = document.querySelector(".button-menu-layer");

    button.setAttribute("data-open", false);

    const lines = gsap.utils.toArray(".line", button);
    const lineContainer = button.querySelector(".line-container");

    gsap.to(lines[0], { y: 0, rotate: 0, duration: 0.35, ease: "power2.out" });
    gsap.to(lines[1], { y: 0, rotate: 0, duration: 0.35, ease: "power2.out" });
}
function headerEvent() {
    if (document.querySelector(".button-menu-layer")) {
        const button = document.querySelector(".button-menu-layer");

        button.addEventListener("click", () => {
            const isOpen = button.getAttribute("data-open") == "true";
            if (isOpen) {
                disappearMenuLayer();
            } else {
                button.setAttribute("data-open", true);
                appearMenuLayer();
            }
        });
    }
}
//
function initFavicon() {
    function darkModeOn() {
        if (window.matchMedia && window.matchMedia("(prefers-color-scheme:dark)").matches) {
            return true;
        } else {
            return false;
        }
    }
    const isDarkMode = darkModeOn();

    const getLink = (icon) => {
        const _origin = icon.href.split("/favicons/");
        icon.href = isDarkMode ? `${_origin[0]}/favicons/${_origin[1].split(".")[0]}-dark.png` : icon.href;
    };
    document.querySelectorAll("[rel=apple-touch-icon]").forEach((icon) => getLink(icon));
    document.querySelectorAll("[rel=icon]").forEach((icon) => getLink(icon));

    document.querySelector("[name=theme-color]").content = isDarkMode ? "#000" : "#fff";
}
function init() {
    // ScrollTrigger.clearScrollMemory();

    gsap.registerPlugin(ScrollSmoother, ScrollTrigger, ScrollToPlugin);
    gsap.config({ nullTargetWarn: false, trialWarn: false });
    // initFavicon();
    initWindowHeight();

    loadPage();

    // textAnim();
}

window.onload = init();
function toggleAnchor(state, noTransition) {
    const _duration = noTransition ? 0 : 0.4;
    const selected = document.querySelector(".select-bg");
    const anchors = gsap.utils.toArray(".header .link");
    if (state === "visible") {
        gsap.to(selected, { opacity: 1, duration: _duration, ease: "power2.inOut" });
    } else {
        gsap.killTweensOf(anchors, "color");
        gsap.to(anchors, { color: "#181923", duration: _duration, ease: "power1.inOut" });
        gsap.to(selected, { opacity: 0, duration: _duration, ease: "power2.inOut" });
    }
}
/** indx / init / noTransition */
function anchorAnim(index, init, noTransition) {
    const _duration = noTransition ? 0 : 0.4;
    let _init = init;
    const selected = document.querySelector(".select-bg");
    if (!selected.hasAttribute("data-load")) {
        _init = true;
    }
    if (_init) {
        toggleAnchor("visible");
    }
    const anchors = gsap.utils.toArray(".header .link");
    const anchor = anchors[index];
    if (anchor) {
        const _left = document.querySelector(".header .gnb-container").getBoundingClientRect().left;

        gsap.to(selected, { width: anchor.clientWidth, duration: _duration, ease: "power2.out" });

        if (document.querySelector(".wrap-dtw")) {
            gsap.to(selected, { left: anchor.getBoundingClientRect().left - _left, duration: _duration, ease: "power2.out" });
        } else {
            gsap.set(selected, { left: anchor.getBoundingClientRect().left - _left });
        }

        anchors.forEach((_anchor, i) => {
            if (i == index) {
                _anchor.classList.add("selected");
                gsap.to(_anchor, { color: "#fff", duration: _duration, ease: "power1.inOut" });
            } else {
                _anchor.classList.remove("selected");
                gsap.to(_anchor, { color: "#181923", duration: _duration, ease: "power1.inOut" });
            }
        });
    }
}

function anchorMenu() {
    const contents = gsap.utils.toArray("section");
    gsap.utils.toArray(".gnb-container").forEach((container) => {
        if (container.hasAttribute("data-custom")) {
            gsap.utils.toArray(".link", container).forEach((link, i) => {
                const _target = link.getAttribute("data-link");
                const content = document.querySelector(`.section-${_target}`);
                if (content === null) {
                    // console.log(_target);
                }

                if (!content.hasAttribute("data-load")) {
                    content.setAttribute("data-load", true);
                    ScrollTrigger.create({
                        trigger: content,
                        id: _target,
                        start: () => `top top`,
                        end: () => `+=${content.offsetHeight}px bottom`,
                        invalidRefresh: true,
                        refreshPriority: 0,

                        onToggle: (self) => {
                            if (self.isActive) {
                                if (!gsap.isTweening(window)) {
                                    anchorAnim(i);
                                }
                            }
                        },
                        onEnter: () => {
                            if (!gsap.isTweening(window)) {
                                anchorAnim(i);
                            }
                        },
                    });
                }

                link.addEventListener("click", () => {
                    anchorToScroll(link, i, true);
                });
            });
        } else {
            gsap.utils.toArray(".link", container).forEach((link, i) => {
                const _target = link.getAttribute("data-link");
                const content = document.querySelector(`.section-${_target}`);
                if (content === null) {
                    // console.log(_target);
                    return;
                }

                if (!content.hasAttribute("data-load")) {
                    content.setAttribute("data-load", true);
                    ScrollTrigger.create({
                        trigger: content,
                        id: _target,
                        start: () => `top top`,
                        end: () => `+=${content.offsetHeight}px`,
                        invalidRefresh: true,
                        refreshPriority: 0,

                        onToggle: (self) => {
                            if (self.isActive) {
                                if (!gsap.isTweening(window)) {
                                    anchorAnim(i);
                                }
                            }
                        },
                        onEnter: () => {
                            if (!gsap.isTweening(window)) {
                                anchorAnim(i);
                            }
                        },
                        onEnterBack: () => {
                            if (!gsap.isTweening(window)) {
                                anchorAnim(i);
                            }
                        },
                    });
                }

                link.addEventListener("click", () => {
                    anchorToScroll(link, i);
                });
            });
        }
    });

    const main = document.querySelector(".section-main-wrap");
    // console.log(main);
    ScrollTrigger.create({
        trigger: main,
        start: () => `top center`,
        end: () => `${ScrollTrigger.getById("about").start - 2} center`,
        invalidRefresh: true,
        refreshPriority: 0,
        // // // // //
        onToggle: (self) => {
            if (self.isActive) {
                if (!gsap.isTweening(window)) {
                    toggleAnchor("hidden");
                }
            }
        },
        onEnter: () => {
            if (!gsap.isTweening(window)) {
                toggleAnchor("hidden");
            }
        },
        onEnterBack: () => {
            if (!gsap.isTweening(window)) {
                toggleAnchor("hidden");
            }
        },
    });

    document.querySelector(".link-main").addEventListener("click", () => anchorToScroll(null, 0));
}

function anchorToScroll(link, i, set) {
    if (gsap.isTweening(window)) return;
    const _link = link;
    let yv = 0;
    let _offsetY = document.querySelector(".lenis") ? -72 : 0;
    const scroller = document.querySelector(".lenis") ? window : document.querySelector(".wrap");
    if (_link == null && i == 0) {
        gsap.to(scroller, { scrollTo: { y: yv, offsetY: _offsetY }, duration: 0.7, ease: "power2.inOut" });
        gsap.delayedCall(0.3, () => {
            toggleAnchor("hidden");
        });
    } else {
        const _target = _link.getAttribute("data-link");
        yv = ScrollTrigger.getById(_target).start;
        _offsetY = window.innerWidth < 1140 ? 0 : 68;

        if (set) {
            gsap.set(scroller, {
                scrollTo: { y: yv, offsetY: _offsetY },
                onComplete: () => {
                    anchorAnim(i, null, true);
                    disappearMenuLayer();
                },
            });
        } else {
            anchorAnim(i);
            gsap.to(scroller, { scrollTo: { y: yv, offsetY: _offsetY }, duration: 0.7, ease: "power2.inOut" });
        }
    }
}

function commonUtilEvents() {
    gsap.utils.toArray(".link-button").forEach((content) => {
        if (!content.hasAttribute("data-custom")) {
            // new ButtonExtend({ content: content });
            buttonExtendCustom(content);
        } else {
            // gsap.set(content, { opacity: 0 });
        }
    });

    gsap.utils.toArray(".txt-fade-container").forEach((container) => {
        // new TextTranslate({ container: container })
        if (!container.hasAttribute("data-custom")) {
            gsap.utils.toArray(".txt-fade", container).forEach((content) => {
                new TextTranslate({ container: content, yValue: 30 });
            });
        }
    });
    var font = new FontFaceObserver("Poppins");

    font.load()
        .then(function () {
            // 폰트가 로드된 후 실행될 코드
            gsap.delayedCall(0.1, () => {
                gsap.utils.toArray(".txt-split-wrap").forEach((container) => {
                    if (!container.hasAttribute("data-custom")) {
                        new TextMasking({ container: container, yValue: "120%", stagger: 0.1, triggerStart: container.getAttribute("data-trigger-start") });
                    }
                });
            });
        })
        .catch(function () {
            console.error("Font failed to load");
        });
    // gsap.delayedCall(0.8, () => {
    //     gsap.utils.toArray(".txt-split-wrap").forEach((container) => {
    //         if (!container.hasAttribute("data-custom")) {
    //             new TextMasking({ container: container, yValue: "120%", stagger: 0.1, triggerStart: container.getAttribute("data-trigger-start") });
    //         }
    //     });
    // });

    gsap.delayedCall(0.6, () => {
        headerBGTrigger();
    });
    gsap.delayedCall(0.8, () => {
        headerAnim();
    });

    gsap.delayedCall(1.0, () => {
        commonContact();
        commonFooter();
    });
}

function commonContact() {
    const section = document.querySelector(".section-contact");
    const textSplit = section.querySelector(".txt-split-wrap");

    ScrollTrigger.create({
        trigger: textSplit,
        start: () => "top+=48% bottom",
        onEnter: () => {
            gsap.delayedCall(0.5, () => {
                gsap.to(section.querySelector(".secion-background"), { width: "100%", duration: 1.0, ease: "power3.out" });
                gsap.to(section.querySelector(".secion-background"), { opacity: 1, duration: 0.8, ease: "power1.inOut" });
            });
        },
    });
    const _callback = () => {
        gsap.delayedCall(3, () => {
            document.body.classList.add("is-full-loaded");
        });
    };
    new TextMasking({ container: textSplit, stagger: 0.15, callback: _callback });
}

function commonFooter() {
    const section = document.querySelector(".footer");

    const button = section.querySelector(".link-button");
    const _button = new ButtonExtend({ content: button, noTrigger: true });
    ScrollTrigger.create({
        trigger: window.innerWidth > 768 ? section.querySelector("img") : button,
        start: () => (window.innerWidth > 768 ? `80% bottom` : `top bottom`),
        // markers: true,
        id: "footer",
        once: true,
        onEnter: () => {
            gsap.to(button, {
                opacity: 1,
                duration: 0.5,
                ease: "power2.inOut",
                onStart: () => {
                    _button.play();
                },
            });
        },
        onComplete: () => {},
    });
}
function buttonExtendCustom(button) {
    gsap.to(button, {
        opacity: 1,
        duration: 0.5,
        ease: "power2.inOut",
        onStart: () => {
            new ButtonExtend({ content: button });
        },
    });
}
function headerBGTrigger() {
    ScrollTrigger.create({
        trigger: ".wrap",
        start: () => `top-=30px top`,
        end: () => `+=${window.innerHeight * 0.7}`,
        // refreshPriority: 0,
        id: "bgTrigger",
        onLeave: () => {
            document.querySelector(".header").classList.add("bg-white");
        },
        onToggle: (self) => {
            if (self.isActive) {
                document.querySelector(".header").classList.remove("bg-white");
            } else {
                document.querySelector(".header").classList.add("bg-white");
            }
        },
    });
}
function headerAnim() {
    if (!document.querySelector(".view-mobile")) {
        const header = document.querySelector(".header");
        const gnb = header.querySelector(".gnb-container");
        const button = header.querySelector(".link-button");

        gsap.to(gnb, {
            y: "0%",
            duration: 1.0,
            ease: "power2.inOut",
            delay: 0.15,
            onComplete: () => {
                gsap.set(gnb, { transform: "translateY(0px)" });
                if (document.querySelector(".wrap-gtaa")) {
                    if (document.querySelector(".gtaa-about")) {
                        anchorAnim(0, true);
                    }
                    if (document.querySelector(".gtaa-telco-llm")) {
                        anchorAnim(1);
                    }
                    if (document.querySelector(".gtaa-events")) {
                        anchorAnim(2);
                    }
                }
            },
        });

        const _dt = document.querySelector(".wrap-dtw") ? 0 : 0.7;
        gsap.delayedCall(_dt, () => {
            buttonExtendCustom(button);
        });
    }
}
