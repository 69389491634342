import p5 from "p5";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollSmoother from "gsap/dist/ScrollSmoother";
import ScrollToPlugin from "gsap/ScrollToPlugin";
// swiper
import "swiper/css";
import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./style/common.css";
import { TextFill, TextFilp, TextMasking, TextTranslate, ImageContast, ButtonExtend } from "./Module";
import CardExtend from "./CardExtend";
import CardBGAnim from "./CardBGAnim";
import sketch from "./visualEvents";

export default class GTAA_Events {
    constructor() {
        this.isMobile = document.body.classList.contains("is-mobile");
        this.init();
    }

    init() {
        gsap.delayedCall(0.3, () => {
            this.sectionMain();
        });

        gsap.delayedCall(0.6, () => {
            this.sectionKeyvisual();
        });

        gsap.delayedCall(0.1, () => {
            // this.sectionProgram();
            // this.sectionSpeaker();
            this.sectionRound();
            this.sectionInnovation();
            this.sectionMilestones();
            this.sectionParticipating();
            // this.sectionTelcoLLM();

            // this.sectionExpect();

            ScrollTrigger.refresh();
        });
    }

    sectionMain() {
        const section = document.querySelector(".section-main");

        gsap.utils.toArray(".typo-mask-container", section).forEach((content, i) => {

            const _text = new TextFill({ content: content, isUp: true });
            _text.play();
        });

        ScrollTrigger.refresh();
    }

    sectionKeyvisual() {
        const sketchInstance = new p5(sketch);

        ScrollTrigger.create({
            trigger: ".section-main",
            start: () => `top-=30px top`,
            end: () => `bottom top`,
            id: "p5",
            onToggle: (self) => {
                if (self.isActive) {
                    sketchInstance.playLoop();
                } else {
                    sketchInstance.stopLoop();
                }
            },
        });
    }

    sectionRound() {
        const section = document.querySelector(".section-roundtable");

        gsap.utils.toArray(".image-wrap", section).forEach((content, i) => {
            new ImageContast({ content: content, delay: i * 0.15 });
            // console.log({ i });
        });
    }
    sectionParticipating() {
        const image = document.querySelectorAll(".section-participating .section-image img");

        ScrollTrigger.create({
            trigger: document.querySelectorAll(".section-participating .section-image"),
            start: () => "top+=50% bottom",
            once: true,
            id: "iamge",

            onEnter: () => {
                gsap.to(image, { opacity: 1, duration: 0.9, ease: "power1.inOut", delay: 0.0 });
                // gsap.to(card.querySelector(".card-background"), { opacity: 1, duration: 1, delay: 0.5, ease: "power1.inOut" });

                // gsap.to(_graphic, { opacity: 1, duration: 0.8, delay: 0.0, ease: "power1.inOut" });
                // gsap.to(_graphic, { scale: 1, duration: 1.2, delay: 0.0, ease: "power2.out" });
                // gsap.to(_graphic, { x: "0%", y: "0%", duration: 1.2, delay: 0.0, ease: "power2.out" });
            },
        });

        ScrollTrigger.refresh();
    }
    sectionInnovation() {
        const section = document.querySelector(".section-innovation");

        const cards = gsap.utils.toArray(".content-card", section);
        const textMasks = [];

        cards.forEach((card, i) => {
            // const _text = new TextMasking({container:card.querySelector('.txt-split-wrap') , isNoTrigger: true })
            // textMasks.push(_text)
            const _sub = card.querySelector(".txt-small");

            ScrollTrigger.matchMedia({
                // large

                "(min-width: 769px)": function () {
                    // console.log(3, that);
                    gsap.set(_sub, { y: 500 });
                    const _delay = 0.15 * i;
                    ScrollTrigger.create({
                        trigger: cards[cards.length - 1].querySelector(".card-text"),
                        start: () => `top+=50% bottom`,
                        // //
                        id: "text",
                        once: true,
                        onEnter: () => {
                            // gsap.to(_graphic, { opacity: 1, duration: 0.8, delay: 0.0, ease: "power1.out" });
                            // gsap.to(_graphic, { scale: 1, y: "0%", duration: 2, delay: 0.0, ease: "power2.out" });

                            gsap.to(_sub, {
                                y: 0,
                                duration: 0.9,
                                ease: "power4.out",
                                delay: _delay,
                            });
                            gsap.to(_sub, {
                                opacity: 1,
                                duration: 0.9,
                                ease: "power4.inOut",
                                delay: _delay,
                            });
                            gsap.to(card.querySelector(".card-background"), { opacity: 1, duration: 1.0, ease: "power2.inOut", delay: 0.2 });
                        },
                    });
                },

                // small
                "(max-width: 768px)": function () {
                    gsap.set(_sub, { y: 150 });
                    ScrollTrigger.create({
                        trigger: card,
                        start: () => `top+=25% bottom`,
                        // //

                        id: "text",
                        once: true,
                        onEnter: () => {
                            // gsap.to(_graphic, { opacity: 1, duration: 0.8, delay: 0.0, ease: "power1.out" });
                            // gsap.to(_graphic, { scale: 1, y: "0%", duration: 2, delay: 0.0, ease: "power2.out" });

                            gsap.to(_sub, {
                                y: 0,
                                duration: 0.9,
                                ease: "power4.out",
                            });
                            gsap.to(_sub, {
                                opacity: 1,
                                duration: 0.9,
                                ease: "power4.inOut",
                            });
                            gsap.to(card.querySelector(".card-background"), { opacity: 1, duration: 1.0, ease: "power2.inOut", delay: 0.2 });
                        },
                    });
                },
            });
        });
    }

    sectionMilestones() {
        const section = document.querySelector(".section-milestones");

        gsap.utils.toArray(".typo-mask-container", section).forEach((content, i) => {

            const _text = new TextFill({ content: content, isUp: true });
        });

        const textMasks = [];

        const cardStagger = 0.15;
        const cards = gsap.utils.toArray(".content-card", section);

        const cardAnimText = (index) => {
            textMasks[index].play();
        };

        const cardAnimGraphic = (card) => {
            gsap.to(card.querySelector(".graphic-mask"), { width: "100%", duration: 1.0, ease: "power3.out" });
            gsap.to(card.querySelector(".card-background"), { opacity: 1, duration: 1.0, ease: "power2.inOut", delay: 0.2 });
        };
        cards.forEach((container, i) => {
            const _text = new TextMasking({ container: container.querySelector(".txt-split-wrap"), isNoTrigger: true });
            textMasks.push(_text);
        });

        ScrollTrigger.matchMedia({
            // large

            "(min-width: 769px)": function () {
                // console.log(3, that);
                ScrollTrigger.create({
                    trigger: gsap.utils.toArray(".content-card", section)[2].querySelector(".txt-split-wrap"),
                    start: () => `top+=50% bottom`,
                    once: true,
                    // markers: "textTrigger",
                    onEnter: () => {
                        textMasks.forEach((t, i) => {
                            gsap.delayedCall(i * cardStagger, () => {
                                cardAnimText(i);
                            });
                        });
                    },
                });

                ScrollTrigger.create({
                    trigger: gsap.utils.toArray(".content-card", section)[1].querySelector(".card-graphic"),
                    start: () => `top+=50% bottom`,
                    once: true,
                    // markers: "textTrigger",
                    onEnter: () => {
                        cards.forEach((c, i) => {
                            gsap.delayedCall(i * cardStagger, () => {
                                cardAnimGraphic(c);
                            });
                        });
                    },
                });
            },

            // small
            "(max-width: 768px)": function () {
                cards.forEach((card, i) => {
                    ScrollTrigger.create({
                        trigger: card.querySelector(".txt-split-wrap"),
                        start: () => `top+=50% bottom`,
                        once: true,
                        onEnter: () => {
                            cardAnimText(i);
                        },
                    });

                    ScrollTrigger.create({
                        trigger: card.querySelector(".card-graphic"),
                        start: () => `top+=50% bottom`,
                        once: true,
                        onEnter: () => {
                            cardAnimGraphic(card);
                        },
                    });
                });
            },
        });

        gsap.utils.toArray(".content-card", section).forEach((container) => {
            const _container = container.querySelector(".txt-split-wrap");
            new TextMasking({ container: _container });
        });

        // new CardBGAnim(section.querySelectorAll('.content-card'))
    }
}
