import p5 from "p5";
import gsap from "gsap";

export default function sketchInner(s) {
    var img;
    s.preload = () => {
        img = s.loadImage("./line.png");
    };

    var stageWidth = 900;
    var stageHeight = 600;
    var cx, cy;

    s.setup = () => {
        const p = s.createCanvas(stageWidth, stageHeight);
        p.parent("grid-visual");
        s.angleMode(s.DEGREES);
        cx = s.width / 2;
        cy = s.height / 2;

        s.movIn();
    };

    var lineSpace = 15;
    var lineWidth = 16;
    s.lineHeight = 450;
    s.waveHeight = 0;

    s.draw = () => {
        s.scale(0.5);
        s.background(255);

        // var wh = 60 + s.cos(s.frameCount * 1.5) * 50;

        for (var i = 0; i < 59; i++) {
            var lh = s.lineHeight + s.cos(s.frameCount * 1.8) * s.lineHeight * 0.2;
            var tx = 0 + i * (lineWidth + lineSpace);
            var ty = cy * 2 + s.cos(s.frameCount * 1.5 + i * s.waveHeight * 0.5) * s.waveHeight - lh * 0.5;
            s.image(img, tx, ty, lineWidth, lh);
        }
    };

    s.movIn = () => {
        gsap.to(s, {
            duration: 1.2,
            waveHeight: 0,
            lineHeight: 20,
            ease: "power2.in",
            onComplete: function () {
                gsap.to(s, {
                    duration: 2.2,
                    waveHeight: 80,
                    lineHeight: 450,
                    ease: "power1.inOut",
                    onComplete: function () {
                        gsap.delayedCall(1, s.movOut);
                    },
                });
            },
        });
    };

    s.movOut = () => {
        gsap.to(s, {
            duration: 0.8,
            waveHeight: 0,
            lineHeight: 900,
            ease: "power2.in",
            onComplete: function () {
                // gsap.to( s, { duration: 0.5, waveHeight: 0, lineHeight: 300, ease: "power2.out", onComplete: function(){

                // } });
                gsap.delayedCall(1, s.movIn);
            },
        });
    };
    s.playLoop = () => {
        s.loop();
    };

    s.stopLoop = () => {
        s.noLoop();
    };
}
