import p5 from "p5";
import gsap from "gsap";

export default function sketch(s) {
    var img;
    var isDraw = false;
    s.preload = () => {
        img = s.loadImage("./line-about.png");
    };

    var stageWidth = 900;
    var stageHeight = 600;

    var cx, cy;

    class Obj {
        constructor(idx, dir) {
            this.idx = idx;
            this.dir = dir;
            this.minHeight = 1500;
            this.lineHeight = this.minHeight;
            this.opacity = 0;
            this.x = this.idx * (lineWidth + 2000) * this.dir;
        }

        changeState(val) {
            if (val == 1) {
                var th = 250 + this.idx * 60;
                var tx = this.idx * (lineWidth + 18) * this.dir;
                gsap.to(this, { duration: 5.5 - this.idx * 0.05, lineHeight: th, ease: "power1.out" });
                gsap.to(this, { duration: 3 + this.idx * 0.05, x: tx, ease: "power1.out" });
            } else {
                var th = this.minHeight;
                var tx = this.idx * (lineWidth + 2000) * this.dir;
                gsap.to(this, { duration: 3 + this.idx * 0.1, lineHeight: th, ease: "power1.out" });
                gsap.to(this, { duration: 3 + this.idx * 0.05, x: tx, ease: "power1.in" });
            }
        }

        update() {
            var space = s.space * this.dir;
            var tx = cx + space + this.x;
            var th = this.lineHeight + s.cos(s.frameCount * 1.2 - this.idx * 15) * this.lineHeight * 0.2;
            var ty = cy - th * 0.5;

            // s.tint(255, 255);
            s.image(img, tx, ty, lineWidth, th);
        }
    }

    var objs = [];

    s.setup = () => {
        const isMobile = window.innerWidth < 768;
        const _width = isMobile ? window.innerWidth * 2 : 1800;
        const _height = isMobile ? window.innerHeight * 1.6 : 1200;
        const p = s.createCanvas(stageWidth, stageHeight);
        p.parent("canvas-wrap");
        s.angleMode(s.DEGREES);

        const _v = window.innerWidth / p.elt.clientWidth;
        gsap.set("#canvas-wrap", { scale: _v < 1 ? 1 : _v.toFixed(2) });

        cx = s.width;
        cy = s.height;

        for (var k = 0; k < 2; k++) {
            for (var i = 0; i < 12; i++) {
                var dir = 1;
                if (k % 2 == 1) dir = -1;
                objs.push(new Obj(i, dir));
            }
        }

        s.movIn();
    };
    s.windowResized = () => {
        if (document.querySelector("#defaultCanvas0")) {
            const _v = window.innerWidth / document.querySelector("#defaultCanvas0").clientWidth;
            gsap.set("#sticky", { scale: (_v < 1 ? 1 : _v.toFixed(2)) / 2 });
        }
    };
    s.lineSpace = 2000;
    s.space = s.lineSpace * 0.5;
    var lineWidth = 20;

    // s.th = 300;

    s.draw = () => {
        s.scale(0.5);
        s.background(0);
        for (var i = 0; i < objs.length; i++) {
            var obj = objs[i];
            obj.update();
        }

        if (!isDraw) {
            isDraw = true;

            gsap.to(".section-main canvas", { opacity: 1, duration: 1.0, ease: "power1.inOut" });
            gsap.set(".section-main canvas", { scaleY: 0.1 });
            gsap.to(".section-main canvas", { scaleY: 1, duration: 2.0, ease: "power1.out", delay: 0.0 });
        }

        let fps = s.frameRate();
        s.fill(255, 255, 255);
        // s.textSize(32);

        // s.text("FPS: " + fps.toFixed(2), window.innerWidth / 2, window.innerHeight / 2);
        // s.text("TOP", 350, 12);
    };

    s.movIn = () => {
        for (var i = 0; i < objs.length; i++) {
            var obj = objs[i];
            obj.changeState(1);
        }
        // gsap.to( s, { duration: 5, lineSpace: 18, space: 40, ease: "power3.out", onComplete: function(){
        gsap.to(s, {
            duration: 5,
            space: 40,
            ease: "power3.out",
            onComplete: function () {
                gsap.delayedCall(4.5, s.movOut);
            },
        });
    };

    s.movOut = () => {
        for (var i = 0; i < objs.length; i++) {
            var obj = objs[i];
            obj.changeState(0);
        }
        // gsap.to( s, { duration: 5, lineSpace: 2000, space: 1000, ease: "power3.inOut", onComplete: function(){
        gsap.to(s, {
            duration: 3.2,
            space: 1000,
            ease: "power3.inOut",
            onComplete: function () {
                gsap.delayedCall(1, s.movIn);
            },
        });
    };

    s.playLoop = () => {
        s.loop();
    };

    s.stopLoop = () => {
        s.noLoop();
    };
}

// const sketchInstance = new p5(sketch);
