import p5 from "p5";
import gsap from "gsap";

export default function sketch(s) {
    var img;
    var isDraw = false;
    s.preload = () => {
        img = s.loadImage("./line.png");
    };

    var stageWidth = 900;
    var stageHeight = 600;
    var cx, cy;

    s.setup = () => {
        const p = s.createCanvas(stageWidth, stageHeight);
        p.parent("canvas-wrap");
        s.angleMode(s.DEGREES);
        cx = s.width / 2;
        cy = s.height / 2;

        // const _v = window.innerWidth / p.elt.clientWidth;
        // gsap.set("#canvas-wrap", { scale: _v < 1 ? 1 : _v.toFixed(2) });
    };
    s.windowResized = () => {
        // const _v = window.innerWidth / document.querySelector("#defaultCanvas0").clientWidth;
        // gsap.set("#canvas-wrap", { scale: _v < 1 ? 1 : _v.toFixed(2) });
    };
    var lineSpace = 15;
    var lineWidth = 16;
    var lineHeight = 550;

    var waveHeight = 5;
    var waveStep = 30;
    var waveSpd = 0.8;

    s.draw = () => {
        s.scale(0.5);
        s.background(255);

        for (var k = 0; k < 2; k++) {
            for (var i = 0; i < 30; i++) {
                var lh = 420; // + s.cos(s.frameCount * 1.0 + i * 10) * 400 * 0.5;
                var tx = cx * 2 + i * (lineWidth + lineSpace);
                var ty = cy + 360 + s.cos(s.frameCount * 1.3 + i * 10) * 100;
                if (k == 1) {
                    ty = cy - 180 + s.sin(s.frameCount * 1.3 + i * 10) * 100;
                }
                s.image(img, tx, ty, lineWidth, lh);
            }
        }
        if (!isDraw) {
            isDraw = true;

            gsap.to(".section-main canvas", { opacity: 1, duration: 1.0, ease: "power1.inOut" });
            gsap.set(".section-main canvas", { scaleY: 0.1 });
            gsap.to(".section-main canvas", { scaleY: 1, duration: 2.0, ease: "power1.out", delay: 0.0 });
        }
    };
    s.playLoop = () => {
        s.loop();
    };

    s.stopLoop = () => {
        s.noLoop();
    };
    s.touchStarted = () => {};
}

// const sketchInstance = new p5(sketch);
