import p5 from "p5";
import gsap from "gsap";
export default function sketch(s) {
    var img;
    var isDraw = false;
    s.preload = () => {
        img = s.loadImage("./line.png");
    };

    var stageWidth = 900;
    var stageHeight = 600;
    var cx, cy;

    s.setup = () => {
        const p = s.createCanvas(stageWidth, stageHeight);
        p.parent("canvas-wrap");
        s.angleMode(s.DEGREES);
        cx = s.width / 2;
        cy = s.height / 2;
        const _v = window.innerWidth / p.elt.clientWidth;
        gsap.set("#canvas-wrap", { scale: _v < 1 ? 1 : _v.toFixed(2) });
    };

    s.windowResized = () => {
        if (document.querySelector("#defaultCanvas0")) {
            const _v = window.innerWidth / document.querySelector("#defaultCanvas0").clientWidth;
            gsap.set("#sticky", { scale: (_v < 1 ? 1 : _v.toFixed(2)) / 2 });
        }
    };

    var lineSpace = 15;
    var lineWidth = 16;
    var lineHeight = 550;

    var waveHeight = 5;
    var waveStep = 30;
    var waveSpd = 0.8;

    s.draw = () => {
        s.scale(0.5);
        s.background(255);

        // lineHeight = 400 + s.sin(s.frameCount * 1.0) * 550 * 0.4;

        for (var i = 0; i < 36; i++) {
            var lh = 400 + s.cos(s.frameCount * 1.0 + i * 10) * 400 * 0.5;
            var th = lh + s.cos(-i * waveStep + s.frameCount * 1.5 + i * 12) * lh * 0.9;
            var ty = cy * 2 - th / 2;
            s.image(img, s.width + i * (lineWidth + lineSpace), ty, lineWidth, th);
        }

        if (!isDraw) {
            isDraw = true;

            gsap.to(".section-main canvas", { opacity: 1, duration: 1.0, ease: "power1.inOut" });
            gsap.set(".section-main canvas", { scaleY: 0.1 });
            gsap.to(".section-main canvas", { scaleY: 1, duration: 2.0, ease: "power1.out", delay: 0.0 });
        }
    };

    s.playLoop = () => {
        s.loop();
    };

    s.stopLoop = () => {
        s.noLoop();
    };
}

// const sketchInstance = new p5(sketch);
